
.cardGlobalStyle{
  :global .ant-card-head{
    background-color: #fafafa;
  }
}

.left {
  :global {
    .ant-card-head-title {
      text-align: left;
    }
  }
}

.center {
  :global {
    .ant-card-head-title {
      text-align: center;
    }
  }
}


.centerBody {
  :global {
    .ant-card-body {
      text-align: center;
    }
  }
}

.right {
  :global {
    .ant-card-head-title {
      text-align: right;
    }
  }
}

.cardNoPadding{
  :global .ant-card-body{
    padding: 1px !important;
  }
}
