.program-card {
  width: 30rem;
  height: 25rem;
  margin: 15px;
  border-radius: 10px;
}

@media (max-width: 600px) {
  .program-card {
    width: 20rem;
    height: 28rem;
    margin: 15px;
    border-radius: 10px;
  }
}
